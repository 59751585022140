import invariant from 'invariant';
import { concat, path, pluck, reduce } from 'ramda';
import { ISupplier_Type } from '@bridebook/models/source/models/Suppliers.types';
import {
  COUNTRY_SEPARATOR,
  addCountryToSearchUrl,
} from '@bridebook/toolbox/src/add-country-to-search-url';
import gazetteer, { CountryCodes, Market } from '@bridebook/toolbox/src/gazetteer';
import { basicFields } from '@bridebook/toolbox/src/google/maps';
import { countriesExcludedTypes } from '@bridebook/toolbox/src/supplier/constants';
import { Slug } from '@bridebook/toolbox/src/types';
import SearchFields from 'app-shared/lib/search/search-fields';
import { ISearchPageQuery, ISearchPageQueryFilters } from 'app-shared/lib/search/types';
import { extractPlaceName } from 'app-shared/lib/search/utils/extract-place-name';
import {
  FiltersSectionOrder,
  sectionOrder,
  sectionOrderBBGlobal,
  sectionOrderDE,
  sectionOrderFR,
  sectionOrderIE,
  sectionOrderUS,
} from 'app-shared/lib/search/utils/section-order';
import { env } from 'lib/env';
import { getI18n } from 'lib/i18n/getI18n';
import { FilterSectionsType, SectionType, StrangeSectionType } from 'lib/search/filter-sections';
import getFiltersPerSupplierCategory from 'lib/supplier/get-filters-per-supplier-category';
import { Bound, TFiltersPerSupplierCategory } from 'lib/types';
import { UrlHelper } from 'lib/url-helper';
import { toTitleCase } from 'lib/utils';
import { prepareObjForElastic } from 'lib/utils/url';
import { ILatLongBounds } from 'lib/weddings/types';
import { AddressComponent, AddressComponentForm, GooglePlace, SectionFiltersType } from './types';

const placesTypes: Array<string> = ['geocode'];

export interface IGetPlaceAutocompleteOptionsProps {
  showOnlyUK?: boolean;
  countryCode?: string;
  establishmentSearch?: boolean;
  bounds: ILatLongBounds;
}

/**
 * @deprecated TODO: [i18n][bb-global] Highly biased towards UK. UK likely could be the generic GB instead.
 */
export function getPlaceAutocompleteOptions({
  showOnlyUK = true,
  countryCode,
  establishmentSearch = false,
  bounds = {
    ne: { lat: -90, lon: 180 },
    sw: { lat: 90, lon: 180 },
  },
}: IGetPlaceAutocompleteOptionsProps): google.maps.places.AutocompleteOptions {
  const result: google.maps.places.AutocompleteOptions = {
    bounds: new google.maps.LatLngBounds(
      new google.maps.LatLng(bounds.sw.lat, bounds.sw.lon),
      new google.maps.LatLng(bounds.ne.lat, bounds.ne.lon),
    ),
    fields: basicFields,
    types: ['establishment'],
  };

  const placesCountry = showOnlyUK ? 'uk' : countryCode;

  if (!establishmentSearch) {
    result.types = placesTypes;
    result.componentRestrictions = placesCountry ? { country: placesCountry } : undefined;
  }

  return result;
}

// analytics helper function
export const getDiff = (
  prev: Array<{ id: string }>,
  next: Array<{ id: string }>,
): Array<string> => {
  const prevList = prev.map((i) => i.id);
  const nextList = next.map((i) => i.id);

  return nextList.filter((x) => prevList.indexOf(x) === -1);
};

function getBoundingBox({ geometry }: GooglePlace): Bound {
  let boundingBox: Bound;

  if (geometry?.viewport) {
    boundingBox = {
      swlat: geometry.viewport.getSouthWest().lat(),
      swlon: geometry.viewport.getSouthWest().lng(),
      nelat: geometry.viewport.getNorthEast().lat(),
      nelon: geometry.viewport.getNorthEast().lng(),
    };
  } else {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const center = geometry!.location!;
    const radius = 1000; // radius in case bounding box is not available

    boundingBox = {
      swlat: google.maps.geometry.spherical.computeOffset(center, radius, -135).lat(),
      swlon: google.maps.geometry.spherical.computeOffset(center, radius, -135).lng(),
      nelat: google.maps.geometry.spherical.computeOffset(center, radius, 45).lat(),
      nelon: google.maps.geometry.spherical.computeOffset(center, radius, 45).lng(),
    };
  }

  return boundingBox;
}

export const extractAreaParams = (area: string) => {
  let location, country;

  const values = area.split(COUNTRY_SEPARATOR);
  if (values.length > 1) {
    country = values.pop();
    if (country && !country.trim().length) {
      country = undefined;
    }
    location = values.join(COUNTRY_SEPARATOR);
  } else {
    location = area;
  }
  return { location, country };
};

export const getIsLocationSearch = (url: string) => /search[/]wedding-[^/]+[/]./.test(url);

export function getArea(place: GooglePlace) {
  const addressComponents = getAddressComponents(place.address_components);
  const area = extractPlaceName(place).replace(/-/g, ' ');

  const countryCode = addressComponents.country;

  // i18n double dash query support
  const adminArea1Component = addressComponents?.administrative_area_level_1
    ? `${COUNTRY_SEPARATOR}${addressComponents?.administrative_area_level_1}`
    : '';
  const areaQuery =
    countryCode === CountryCodes.GB
      ? area
      : addCountryToSearchUrl(`${area}${adminArea1Component}`, countryCode);
  return areaQuery;
}

export function getCoordinates(place: GooglePlace, type: Slug): Partial<SearchFields> {
  const bboxCoords: Bound = getBoundingBox(place);
  const area = getArea(place);
  return {
    area,
    type,
    ...bboxCoords,
  };
}

export const getAddressComponents = (
  addressComponents?: AddressComponent[],
): AddressComponentForm<string> | Record<string, never> => {
  if (!addressComponents) return {};
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    postal_town: 'long_name',
    locality: 'long_name',
    administrative_area_level_1: 'long_name',
    administrative_area_level_2: 'short_name',
    administrative_area_level_3: 'short_name',
    administrative_area_level_4: 'short_name',
    country: 'short_name',
    postal_code: 'short_name',
  };

  // Get each component of the address from the place details
  return Object.keys(addressComponents).reduce((prev: Record<string, any>, _, index) => {
    const address = addressComponents[index];
    const addressType = address.types[0];
    const value = componentForm[addressType as keyof typeof componentForm];
    if (value) {
      const val = address[value as keyof typeof address];
      prev[addressType] = val;
    }
    return prev;
  }, {});
};

export const getFilterSectionProps = (
  sec: Array<SectionType>,
  filtersPerSupplierCategory: TFiltersPerSupplierCategory,
) => {
  invariant(Array.isArray(sec), 'Expected sec to be array.');
  invariant(
    typeof filtersPerSupplierCategory === 'object' && !Array.isArray(filtersPerSupplierCategory),
    'Expected supplier to be object.',
  );

  return sec.map((section) => {
    const newSection = { ...section };
    if (newSection.showAllFields) {
      newSection.fieldsToShow = Object.keys(
        filtersPerSupplierCategory[newSection.field as keyof TFiltersPerSupplierCategory] as object,
      ) as SectionType['fieldsToShow'];
    }
    return newSection;
  });
};

export const getFilterCount = (filters: object) => {
  if (Object.keys(filters).length === 0) return 0;
  const filterItems = prepareObjForElastic(filters);
  const count = Object.keys(filterItems).length;
  const exception =
    (filterItems.capacityDining && filterItems.capacityDining === '0-300') ||
    filterItems.capacityDining === '0-10000'
      ? 1
      : 0;
  return count - exception;
};

export const getSectionFilters = (
  type: Slug,
  filtersSections: FilterSectionsType,
  filtersPerSupplierCategory: TFiltersPerSupplierCategory,
): Array<Array<SectionFiltersType>> => {
  const filterSection = filtersSections[type];
  const mapSections = getFilterSectionProps(
    filterSection ? filterSection.sections : [],
    filtersPerSupplierCategory,
  );
  const newSections = mapSections.map((section) => ({
    field: section.field, // section title
    fieldsToShow: section.fieldsToShow.map((field) => {
      // on venue there is already field under fieldsToShow
      if ((field as StrangeSectionType).field) {
        return field;
      }
      // if not venue transform it into expected result
      return {
        field, // section or subsection title
        section: section.field,
      };
    }),
  }));
  return pluck('fieldsToShow', newSections) as Array<Array<SectionFiltersType>>;
};

// unknown type for supplier is because of packages/web/lib/supplier/create-supplier.ts(644)
export const getFilters = (
  type: Slug,
  filtersSections: FilterSectionsType,
  filtersPerSupplierCategory: TFiltersPerSupplierCategory,
): Array<SectionFiltersType> => {
  const concatAll = reduce<SectionFiltersType[], SectionFiltersType[]>(concat, []);
  return concatAll(getSectionFilters(type, filtersSections, filtersPerSupplierCategory));
};

export const getSectionByFilterName = (filters: Array<SectionFiltersType>, name: string) => {
  const filter = filters.find(({ field }) => field === name);

  return filter ? filter.section : '';
};

// filter is checked if filters.[field] or filters.section.[field] is present
// gets the filters and the path to the field
// returns true if it's found
export const filterIsActive =
  (filters: Record<string, any>) =>
  ({ section, field }: SectionFiltersType) =>
    path([field], filters) || (section && path([section, field], filters));

export const getActiveFilters = (
  type: Slug,
  filtersSections: FilterSectionsType,
  filtersPerSupplierCategory: TFiltersPerSupplierCategory,
  searchFilters: Record<string, any>,
): SectionFiltersType[] =>
  getFilters(type, filtersSections, filtersPerSupplierCategory).filter(
    filterIsActive(searchFilters),
  );

/**
 * @deprecated TODO: [i18n][bb-global] This is not a scalable solution.
 */
const isUK = (area: string) =>
  area.toLowerCase() === 'united-kingdom' ||
  area.toLowerCase() === 'united kingdom' ||
  area.toLowerCase() === 'uk';

export const getLocationInfoPhrasing = (area: string, isLoggedIn: boolean): string => {
  const i18n = getI18n();
  try {
    if (isUK(area)) {
      return i18n.t('common:inArea.uk');
    }

    // In some languages, the logged out text needs to be different (for SEO)
    return isLoggedIn
      ? i18n.t('common:inArea.offLocationInfo')
      : i18n.t('common:inArea.offLocationLoggedOut');
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('getLocationInfoPhrasing error:', error);
    return i18n.t('common:inArea.default');
  }
};

export const getFiltersFromQuery = ({
  area,
  sort,
  slug,
  page,
  searchPopup,
  searchParams,
  nelat,
  nelon,
  swlat,
  swlon,
  zoom,
  searchOnMove,
  preset,
  ...filters
}: ISearchPageQuery): ISearchPageQueryFilters => filters;

export const getDefaultFakeSearchInputText = (): string =>
  getI18n().t('home:searchInputPlaceholder', 'Choose a location');

/**
 * @deprecated TODO: [i18n][bb-global] Humm...
 */
export const getFakeSearchInputText = (area: string, type?: string) => {
  const name = area.trim();

  return type === 'home' && (name === '' || name === 'uk')
    ? getDefaultFakeSearchInputText()
    : toTitleCase(name);
};

/**
 * @deprecated TODO: [i18n][bb-global] This is not a scalable solution.
 */
export const getUKFiltersSections = (sectionOrder: FiltersSectionOrder): FiltersSectionOrder => {
  const section = sectionOrder?.overviewSection;
  const overviewSection = env.BEST_OF_BRITAIN
    ? section
    : section?.filter?.((s: string) => s !== 'bestOfBritain');

  return { ...sectionOrder, overviewSection };
};

/**
 * @deprecated TODO: [i18n][bb-global] This is not a scalable solution.
 */
export const countryCodeToSectionOrder: Record<string, FiltersSectionOrder> = {
  [CountryCodes.GB]: getUKFiltersSections(sectionOrder) || {},
  [CountryCodes.DE]: sectionOrderDE,
  [CountryCodes.US]: sectionOrderUS,
  [CountryCodes.FR]: sectionOrderFR,
  [CountryCodes.IE]: sectionOrderIE,
  [CountryCodes.AU]: sectionOrderBBGlobal,
  [CountryCodes.CA]: sectionOrderBBGlobal,
  bbGlobal: sectionOrderBBGlobal,
};

// Use country specific filters
// TODO: refactor filters configuration. Extract from msg and supplier schema.
export const getCountryFilters = (countryCode: string): FiltersSectionOrder => {
  const sectionOrder = (() => {
    if (countryCodeToSectionOrder[countryCode]) {
      return countryCodeToSectionOrder[countryCode];
    }

    switch (countryCode) {
      case CountryCodes.AT:
      case CountryCodes.CH:
        return sectionOrderDE;
      default:
        return countryCodeToSectionOrder['bbGlobal'];
    }
  })();

  if (!countriesExcludedTypes[countryCode]) return sectionOrder;
  return Object.keys(sectionOrder).reduce((acc, key) => {
    acc[key as keyof typeof sectionOrder] = sectionOrder[key as keyof typeof sectionOrder].filter(
      (type: string) => !countriesExcludedTypes[countryCode].has(type),
    );

    return acc;
  }, {} as typeof sectionOrder);
};

/*
  Convert 'true' and 'false' strings to booleans
 */
export const convertFilterValue = <T>(filterValue: any): T =>
  filterValue === 'true' ? true : filterValue === 'false' ? false : filterValue;
/*
 Parse stringified values of filters and
 ensure correct filters structure coming from search response
 */
export const formatFiltersFromSearch = (
  filters: Record<string, Record<string, string | boolean>>,
) => {
  const newFilters: Record<string, Record<string, string | boolean>> = {};

  Object.entries(filters).forEach(([section, sectionFilters]) => {
    Object.entries(sectionFilters).forEach(([filterName, filterValue]) => {
      if (!newFilters[section]) {
        newFilters[section] = {};
      }
      newFilters[section][filterName] = convertFilterValue(filterValue);
    });
  });
  return newFilters;
};

/**
 * @deprecated TODO [i18n][bb-global] Use `Market.country` instead.
 */
export const getDefaultSearchArea = ({
  countryCode,
  locale,
}: {
  countryCode: CountryCodes;
  locale?: string;
}): string => {
  try {
    return gazetteer.getMarketByCountry(countryCode).getCountryName();
  } catch (error) {
    return gazetteer.getMarketByURL(locale, CountryCodes.GB).getCountryName();
  }
};

export const formatArea = (area: string, market: Market) => {
  const location = toTitleCase(
    area
      .replace(new RegExp(`${COUNTRY_SEPARATOR}${market.country ?? ''}$`, 'i'), '')
      .replace(/\+|%2B/g, ' ')
      .replace(/,|%2C/g, '')
      .replace(/-/g, ' '),
  );

  return formatAreaWithCountry(location, market);
};

export const formatAreaWithCountry = (locationTitle: string, market: Market) => {
  const isCoreMarket = market.flags.monetized;
  const countryName = market.getCountryName();
  // For non-core markets, show also the country name (except for the country-wide search)
  return isCoreMarket || locationTitle === countryName
    ? locationTitle
    : `${locationTitle}, ${countryName}`;
};

/**
 * @deprecated - search landing page was removed
 */
export const isSearchLandingPage = (pathname: string) => pathname === UrlHelper.searchLanding;
export const isGlobalLocationsPage = (pathname: string) =>
  pathname.includes(UrlHelper.globalLocations);

/**
 * @deprecated - search landing page was removed
 */
export const isSearchResultsOrLanding = (pathname: string) => pathname.startsWith(UrlHelper.search);

export const isSearchResultsPage = (pathname: string) =>
  pathname.includes(UrlHelper.search) && !isSearchLandingPage(pathname);

/**
 * @deprecated TODO: [i18n][bb-global] This is not a scalable solution.
 */
export const isGenericCountryLocation = (searchArea: string) =>
  searchArea === 'uk' || searchArea === 'fr' || searchArea === 'de' || searchArea === 'ie';

export const isOnboardingPage = (pathname: string) => pathname.includes(UrlHelper.onboarding);
export const isCollaboratorOnboardingPage = (pathname: string) =>
  pathname.includes(UrlHelper.collaboratorOnboarding);

export const isPlanningPage = (pathname: string) => pathname.includes(UrlHelper.planning);

export const getAllFilters = (slug: ISupplier_Type, filtersSections: FilterSectionsType) => {
  if (slug === 'group') {
    return [];
  }

  const filtersPerSupplier = getFiltersPerSupplierCategory(slug);
  return [
    ...getFilters(slug, filtersSections, filtersPerSupplier),
    { field: 'accommodationRooms', section: 'misc' },
    { field: 'capacityDining', section: 'misc' },
    { field: 'radiusIncrease', section: 'misc' },
  ];
};
