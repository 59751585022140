import { keys } from 'ramda';
import { useEffect, useState } from 'react';
import { mapUrlSlugToSupplierType } from '@bridebook/toolbox';
import { toMillis } from '@bridebook/toolbox/src/time-utils/time-utils';
import { UrlFriendlySlug } from '@bridebook/toolbox/src/types';
import { useQuery } from '@tanstack/react-query';
import { createFullQuery } from 'app-shared/lib/search/utils';
import { isWhitelistedSupplierLocale } from 'app-shared/lib/supplier/utils/is-whitelisted-supplier-locale';
import { fetchSearchCountPromise } from 'lib/search/actions';
import { SearchQueryKey } from 'lib/search/constants';
import { useLocationData } from 'lib/search/hooks/query/use-location-data';
import {
  ISearchParams,
  getDerivedParams,
  useSearchParams,
} from 'lib/search/hooks/query/use-search';
import { getFilters, getFiltersSections, getSearchTotalResults } from 'lib/search/selectors';
import { IFetchSearchPromiseArgs } from 'lib/search/types';
import { getAllFilters } from 'lib/search/utils';
import { prepareObjForElastic, useSelector } from 'lib/utils';
import { assertExists } from 'lib/utils/assertExists';

const SEARCH_COUNT_QUERY_CACHE_TIME = toMillis(15, 'min');

const useSearchCount = (params?: ISearchParams) => {
  const { locationData, isLocationDataFetched } = useLocationData({
    slug: params?.slug,
    area: params?.area,
    market: params?.market,
  });

  const { data: searchData, isLoading: isSearchDataLoading } = useQuery(
    [SearchQueryKey.SearchCountData, params],
    async ({ signal }) => {
      if (params === undefined) return;
      const derivedParams = await getDerivedParams(params);
      const fullQuery = createFullQuery(params);
      const request: IFetchSearchPromiseArgs = {
        ...fullQuery,
        ...derivedParams,
        page: params.page,
        isLoggedIn: true,
        locationData,
        countryCode: params.market.country,
      };

      const result = {
        fullQuery,
        params,
        request,
        response: await fetchSearchCountPromise(request, signal),
        locationData: locationData ?? null,
      };

      const localeWhitelisted = isWhitelistedSupplierLocale({
        supplierCountry: result.request?.countryCode,
        market: params.market,
        isLoggedIn: true,
      });

      if (!result.response || !localeWhitelisted) {
        throw new Error();
      }

      return result;
    },
    {
      cacheTime: SEARCH_COUNT_QUERY_CACHE_TIME,
      staleTime: SEARCH_COUNT_QUERY_CACHE_TIME,
      enabled: isLocationDataFetched && !!params,
    },
  );

  return {
    data: searchData?.response.totalResults,
    isLoading: isSearchDataLoading || !isLocationDataFetched,
  };
};

export const useSearchCountWithSelectedFilters = () => {
  const currentResultCount = useSelector(getSearchTotalResults);
  const [totalNumber, setTotalNumber] = useState(currentResultCount);
  const filters = useSelector(getFilters);
  const filtersSections = useSelector(getFiltersSections);
  const elasticFilters = Object.entries(prepareObjForElastic(filters)).reduce<
    Record<string, string>
  >((acc, curr) => {
    acc[curr[0]] = curr[1].toString();
    return acc;
  }, {});
  const searchParams = useSearchParams();

  const slug = searchParams?.searchParams[0]
    ? (searchParams?.searchParams[0].substring('wedding-'.length) as UrlFriendlySlug)
    : searchParams?.slug || 'venues';

  const supplierSlug = mapUrlSlugToSupplierType(slug);
  assertExists(supplierSlug);

  const allFilters = getAllFilters(supplierSlug, filtersSections).map((filter) => filter.field);

  const searchParamsRawKeys = searchParams
    ? keys(searchParams).filter((key) => !allFilters.includes(key))
    : [];

  const params = {
    ...searchParamsRawKeys.reduce((acc, key) => {
      if (searchParams) {
        acc = { ...acc, [key]: searchParams[key] };
      }
      return acc;
    }, {} as ISearchParams),
    ...elasticFilters,
  };

  const result = useSearchCount(params);

  useEffect(() => {
    if (!result.isLoading && result.data !== undefined) {
      setTotalNumber(result.data);
    }
  }, [result.data, result.isLoading]);

  return totalNumber;
};
