import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';
import { CountryCodes } from '@bridebook/toolbox/src/gazetteer';
import { getWeddingL10n } from 'lib/i18n/selectors';
import { IApplicationState, ISearchState } from 'lib/types';
import { assertState } from 'lib/utils/assertState';
import { createDeepEqualSelector } from 'lib/utils/selectors';
import { getCountryCodeFromArea } from './utils/get-country-code-from-area';
import { isSupplierWithCoordinates } from './utils/is-supplier-coordinates';

const _getSearchState = (state: IApplicationState) => state.search;
const _getSearchMapState = (state: IApplicationState) => _getSearchState(state).map;
const _getSearchLocationDraftSearchText = (state: IApplicationState) => {
  assertState(state.search.searchLocation, 'initialized', 'search');
  return state.search.searchLocation.draft.searchText;
};

const filters = (state: IApplicationState) => state.search.filters;

export const getSearchFields = createSelector(_getSearchState, ({ results, request }) =>
  results.status === 'loaded'
    ? results.fields
    : { ...request, type: request.slug, searchType: '', addressComponents: undefined },
);

export const getHasNoResults = createSelector(_getSearchState, ({ noResults }) => noResults);
export const getLastRowPosition = createSelector(
  _getSearchState,
  ({ lastRowPosition }) => lastRowPosition,
);
export const getFiltersState = createDeepEqualSelector(filters, (filters) => filters);
export const getFilters = createDeepEqualSelector(filters, (filters) => filters.data);

export const getFiltersCount = createDeepEqualSelector(
  [_getSearchState, getFilters],
  ({ filtersCount }) => filtersCount,
);
export const areFiltersShown = createSelector(_getSearchState, ({ filtersShown }) => filtersShown);

export const getPreset = createSelector(_getSearchState, ({ preset }) => preset);

export const getSearchList = createSelector(_getSearchState, ({ list }) => list);

export const getIsSearchLoaded = createSelector(_getSearchState, ({ loaded }) => loaded);

export const getSearchPagination = createSelector(_getSearchState, (search) => {
  if (search.results.status !== 'loaded') {
    return null;
  }

  return {
    pageCount: Math.ceil(search.totalResults / (search.results.fields?.size ?? 1)),
    pageCurrent: search.results.fields.page,
  };
});

export const getSearchMarkers = createSelector(getSearchList, (list) =>
  list.filter(isSupplierWithCoordinates),
);

export const getIsMapView = createSelector(_getSearchState, ({ isMapView }) => isMapView);

export const getMapMovedCoords = createSelector(
  _getSearchMapState,
  ({ mapMovedCoords }) => mapMovedCoords,
);

export const getFiltersSections = (state: IApplicationState) => state.search.filtersSections;

export const getSupplierById = createCachedSelector(
  getSearchList,
  (_: IApplicationState, supplierId: string) => supplierId,
  (list, supplierId) => list.filter(({ id }) => supplierId === id)[0],
)((_, supplierId) => supplierId);

export const getSearchedSupplierName = createCachedSelector(
  getSupplierById,
  (supplier) => supplier?.name,
)((_, supplierId) => supplierId);

const getHighlightedMarkerId = createSelector(
  _getSearchMapState,
  ({ highlightedMarkerId: highlightedSupplierId }) => highlightedSupplierId,
);

export const getClickedMarkerId = createSelector(
  _getSearchMapState,
  ({ clickedMarkerId: clickedSupplierId }) => clickedSupplierId,
);

export const getHighlightedSupplier = createCachedSelector(
  (state: IApplicationState, supplierId: string | undefined) =>
    supplierId ? getSupplierById(state, supplierId) : null,
  (supplier) => supplier,
)((_, supplierId) => supplierId || 'undefined');

export const getIsHighlightedMarker = createCachedSelector(
  getHighlightedMarkerId,
  (_: IApplicationState, supplierId: string) => supplierId,
  (highlightedSupplierId, supplierId) => highlightedSupplierId === supplierId,
)((_, supplierId) => supplierId);

export const getIsClickedMarker = createCachedSelector(
  getClickedMarkerId,
  (_: IApplicationState, supplierId: string) => supplierId,
  (highlightedSupplierId, supplierId) => highlightedSupplierId === supplierId,
)((_, supplierId) => supplierId);

export const getSearchLocationData = createSelector(
  _getSearchState,
  (search) => search.locationData,
);

export const getSearchRequestFormattedArea = createSelector(
  _getSearchState,
  (search: ISearchState) => search.request.formattedArea,
);

export const checkIsSearchLocationInitialized = createSelector(
  _getSearchState,
  (search) => search.searchLocation.status === 'initialized',
);
export const getSearchLocation = createSelector(_getSearchState, (search) => {
  assertState(search.searchLocation, 'initialized', 'search');
  return search.searchLocation;
});
export const getPopularVenueTypes = createSelector(
  _getSearchState,
  (search) => search.popularVenueTypes,
);

export const getSearchRequest = createSelector(_getSearchState, ({ request }) => request);

export const getSearchRequestSlug = createSelector(_getSearchState, ({ request }) => request.slug);
export const getSearchRequestArea = createSelector(_getSearchState, ({ request }) => request.area);
export const getSearchRequestSort = createSelector(_getSearchState, ({ request }) => request.sort);

export const getSearchLocationCounty = createSelector(_getSearchState, ({ results }) => {
  if (results.status === 'loaded') {
    return results.fields.addressComponents?.administrative_area_level_2;
  }
  return '';
});

export const getSearchTotalResults = createSelector(
  _getSearchState,
  ({ totalResults }) => totalResults,
);

export const getIsVenueSearch = createSelector(getSearchRequestSlug, (slug) => slug === 'venue');

export const getIsPhotographerSearch = createSelector(
  getSearchRequestSlug,
  (slug) => slug === 'photo',
);

export const selectSearchFieldType = createSelector(
  (state: IApplicationState) => state.search.request.slug,
  (type) => type,
);

export const selectSelectedSearchText = createSelector(
  (state: IApplicationState) => state.search.searchLocation,
  (searchLocation) => {
    assertState(searchLocation, 'initialized', 'search');
    return searchLocation.selected.searchText;
  },
);

export const getSelectedSearchCountryCode = createSelector(
  (state: IApplicationState) => state.search.searchLocation,
  (searchLocation) => {
    assertState(searchLocation, 'initialized', 'search');
    return searchLocation.selected.countryCode;
  },
);

export const selectDraftSearchText = (state: IApplicationState) => {
  assertState(state.search.searchLocation, 'initialized', 'search');
  return state.search.searchLocation.draft.searchText;
};

export const selectDraftIsLocationUnknown = (state: IApplicationState) => {
  assertState(state.search.searchLocation, 'initialized', 'search');
  return state.search.searchLocation.draft.unknownLocation;
};

export const selectAutocompleteText = createSelector(
  [_getSearchLocationDraftSearchText],
  (selectedSearchLocation) => selectedSearchLocation,
);

export const selectShowQuickFiltersContainer = createSelector([getSearchRequestSlug], (slug) =>
  ['photo', 'venue', 'music', 'catering'].includes(slug ?? ''),
);

export const getIsLateAvailabilityFilterSelected = createSelector(
  getFilters,
  (filters) => filters?.specialOffers?.hasLateAvailability,
);

export const getFiltersLoaded = createSelector(
  getFiltersState,
  (filters) => filters.status === 'loaded',
);

export const getShowAllSupplierTypes = createSelector(
  _getSearchState,
  (search) => search.showAllSupplierTypes,
);

export const getIsGlobalCountrySearch = (searchArea: string) =>
  createSelector([getWeddingL10n], (weddingCountry) => {
    const searchCountryCode = getCountryCodeFromArea(searchArea);
    const isCountrySearch =
      searchCountryCode === CountryCodes.GB
        ? searchArea.split('--').pop()?.toUpperCase() === 'UNITED KINGDOM'
        : searchArea.split('--').length <= 2;
    // country search is global when wedding country is different than search country
    return searchCountryCode !== weddingCountry.country && isCountrySearch;
  });

export const getSearchModalTab = createSelector(_getSearchState, (search) => search.searchModal);

export const getIsSearchModalOpen = createSelector(
  getSearchModalTab,
  (searchModal) => !!searchModal,
);

export const getSearchSuggestions = createSelector(_getSearchState, (search) => search.suggestions);

export const getSearchSuggestionsLoading = createSelector(
  _getSearchState,
  (search) => search.suggestionsLoading,
);

export const getDistanceSortDisabled = createSelector(
  _getSearchState,
  (search) => search.distanceSortDisabled,
);

export const getSearchSource = createSelector(_getSearchState, (search) => search.searchSource);

export const getOnboardingFilters = createSelector(
  _getSearchState,
  (search) => search.onboardingFilters,
);

export const getOnboardingFiltersSnackbarShown = createSelector(
  _getSearchState,
  (search) => search.onboardingFiltersSnackbarShown,
);

export const getFiltersTab = createSelector(_getSearchState, (search) => search.filtersTab);

export const getSortingOption = createSelector(_getSearchState, (search) => search.sortOption);

export const getVisitedMarkerIds = createSelector(
  _getSearchMapState,
  ({ visitedMarkerIds }) => visitedMarkerIds,
);
