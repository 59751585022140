/**
 * Hooks exported here are imported and initialized in `useGlobalABTests` hook
 */
import { useABTest_RequestABrochureCTAinFR } from 'lib/ab-tests/tests/LIVE-22546_RequestABrochureCTAInFR';
import { useABTest_ForcePhoneNumber } from 'lib/ab-tests/tests/global/LIVE19141_ForceIEAndFRCouplesToEnterPhoneNumberBeforeSendingEnquiries';
import { useABTest_CakeQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_CakeQuizSearchIntercept';
import { useABTest_FloristQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_FloristQuizSearchIntercept';
import { useABTest_HairAndBeautyQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_HairAndBeautyQuizSearchIntercept';
import { useABTest_PhotoQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_PhotoQuizSearchIntercept';
import { useABTest_VideographerQuizSearchIntercept } from 'lib/ab-tests/tests/global/LIVE-19024_VideographerQuizSearchIntercept';
import { useABTest_EnableGlobalSearch } from 'lib/ab-tests/tests/global/LIVE-19547_EnableGlobalSearch';
import { useABTest_BrochureRequestFastResponse } from 'lib/ab-tests/tests/global/LIVE-21330_BrochureRequestFastResponse';
import { useABTest_RandomiseEnquiryMessage } from 'lib/ab-tests/tests/global/LIVE-22479_RandomiseEnquiryMessage';

export const globalABTests: (() => void)[] = [
  useABTest_CakeQuizSearchIntercept,
  useABTest_FloristQuizSearchIntercept,
  useABTest_PhotoQuizSearchIntercept,
  useABTest_VideographerQuizSearchIntercept,
  useABTest_HairAndBeautyQuizSearchIntercept,
  useABTest_EnableGlobalSearch,
  useABTest_RandomiseEnquiryMessage,
  useABTest_ForcePhoneNumber,
  useABTest_BrochureRequestFastResponse,
  useABTest_RequestABrochureCTAinFR,
];
